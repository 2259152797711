/* eslint-disable @typescript-eslint/no-explicit-any */
import { useOnClickOutside } from '@hooks';
import { Value } from '@radix-ui/react-select';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  StyledChevronDown,
  StyledContent,
  StyledErrorMessage,
  StyledHint,
  StyledIconContainer,
  StyledInfoIcon,
  StyledLabel,
  StyledPopover,
  StyledScrollContainer,
  StyledSelect,
  StyledUl,
  StyledValueContainer,
  StyledWrapper,
} from './Select.styles';
import { SelectProps } from './Select.types';

const SelectRoot = forwardRef(
  (
    {
      children,
      hint,
      error,
      icon,
      label,
      onChange,
      onClose,
      multiple = false,
      displayText = 'items selected',
      shape = 'square',
      size = 'large',
      defaultValue,
      name,
      value,
      disabled,
      placeholder = 'Select a value',
      labelProps,
      width = '100%',
      onBlur,
      id,
      clicked,
      ...restProps
    }: SelectProps,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const innerRef = useRef<HTMLButtonElement>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [valueState, setValueState] = useState<any>('');
    const [selectedLabel, setSelectedLabel] = useState<string>('');

    useEffect(() => {
      if (multiple) {
        const initialMultipleState = value ?? defaultValue;
        setValueState(initialMultipleState ?? []);
      }
    }, [defaultValue, multiple, value]);

    useEffect(() => {
      setIsOpen(false);
    }, [value, clicked]);

    useEffect(() => {
      if (onClose && typeof onClose === 'function') {
        if (!isOpen) {
          return onClose(onClose);
        }
      }
      return null;
    }, [isOpen, onClose]);

    const hideSelect = useCallback((event) => {
      // Prevent popover not opening on mobile devices
      if (!innerRef.current || innerRef.current.contains(event.target)) {
        return;
      }

      setIsOpen(false);
    }, []);

    useOnClickOutside(containerRef, hideSelect);

    function handleChange(changeValue: string) {
      handleOnChangeCallback(changeValue);

      if (multiple) {
        if (valueState.includes(changeValue)) {
          return setValueState((state: string[]) =>
            state.filter((item: string) => item !== changeValue)
          );
        }

        return setValueState((state: string | string[]) =>
          Array.isArray(state) ? [...state, changeValue] : [state, changeValue]
        );
      }
      setIsOpen(false);
      return setValueState(changeValue);
    }

    function handleOnChangeCallback(changedValue: string) {
      if (onChange && typeof onChange === 'function') {
        if (multiple) {
          if (valueState.includes(changedValue) && Array.isArray(valueState)) {
            return onChange(valueState.filter((item: string) => item !== changedValue));
          }

          return onChange([...valueState, changedValue]);
        }

        return onChange(changedValue);
      }

      return null;
    }

    useImperativeHandle(forwardedRef, () => innerRef?.current);

    const renderValue = () => {
      if (valueState.length === 1) {
        return selectedLabel;
      }

      if (valueState.length === 0) {
        return placeholder;
      }

      return `${valueState.length} ${displayText}`;
    };

    const onMultipleChange = (selectedText: string) => setSelectedLabel(selectedText);

    const mappedChildren = React.Children.map(
      children as React.ReactElement[],
      (child: React.ReactElement) =>
        React.cloneElement(child, {
          ...child.props,
          multiple,
          selectedValues: valueState,
          disabled: disabled || child.props.disabled,
          onMultipleChange,
        })
    );

    return (
      <StyledWrapper
        onValueChange={(changedValue) => handleChange(changedValue)}
        onOpenChange={(open) => setIsOpen(multiple ? true : open)}
        open={isOpen}
        css={{ width }}
        name={name}
        defaultValue={defaultValue as string}
        value={multiple ? '' : (value as string)}
      >
        {label && (
          <StyledLabel disabled={disabled} error={Boolean(error)} {...labelProps}>
            {label}
          </StyledLabel>
        )}
        {hint && <StyledHint>{hint}</StyledHint>}
        {error && (
          <StyledErrorMessage>
            <StyledInfoIcon size="tiny" />
            {error}
          </StyledErrorMessage>
        )}
        <StyledSelect
          size={size}
          error={Boolean(error)}
          disabled={disabled}
          shape={shape}
          ref={innerRef}
          name={name}
          data-testid={(restProps as Record<string, unknown>)['data-testid']}
          id={id}
          css={{ width }}
        >
          {icon && <StyledIconContainer>{icon}</StyledIconContainer>}
          <StyledValueContainer>
            {multiple ? <Value placeholder={value}>{renderValue()}</Value> : <>{value}</>}
          </StyledValueContainer>
          <StyledChevronDown size="medium" />
        </StyledSelect>
        <StyledPopover onBlur={onBlur}>
          <StyledContent
            ref={(ref: HTMLDivElement) => {
              containerRef.current = ref;
              if (ref) {
                ref.addEventListener('touchend', (e) => e.preventDefault());
              }
            }}
            position="popper"
          >
            <StyledScrollContainer>
              <StyledUl>{mappedChildren}</StyledUl>
            </StyledScrollContainer>
          </StyledContent>
        </StyledPopover>
      </StyledWrapper>
    );
  }
);

export default SelectRoot;
