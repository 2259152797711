import { SkeletonLoader } from '@hexa-ui/components';
import { ItemDefaultFalse } from './TabMenuItemLoading.styles';

export const TabMenuItemLoading = () => {
  return (
    <ItemDefaultFalse>
      <SkeletonLoader width="185px" height="48px" data-testid="skeleton-loader" />
    </ItemDefaultFalse>
  );
};
