import { styled } from '@hexa-ui/theme';

export const Wrapper = styled('div', {
  height: '52px',
  position: 'relative',
  boxSizing: 'borderBox',
});

export const SearchFieldWrapper = styled('div', {
  marginInline: '12px',
});

export const TextLinkWrapper = styled('div', {
  padding: '16px',
});

export const SelectedOption = styled('div', {
  padding: '16px',
  cursor: 'pointer',
  backgroundColor: '#1414140A',
});

export const Option = styled('div', {
  padding: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$brandAccentNeutralBackgroundOpOpaque',
  },
});

export const NoResultsWrapper = styled('div', {
  maxWidth: '248px',
  padding: '16px',
});

export const ListWrapper = styled('div', {
  height: '48vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
    visibility: 'visible !important',
    display: 'block',
    opacity: '1',
    backgroundColor: 'red',
    height: '50vh',
    position: 'fixed !important',
    zIndex: '9999',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#F1F1F1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    visibility: 'visible !important',
  },
});
