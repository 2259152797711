import { useIntl } from 'react-intl';
import * as Styles from './KpiLabelStatus.styles';
import { KpiLabelStatusProps } from './KpiLabelStatus.types';

export const KpiLabelStatus = ({
  statusOfKpi,
  hasCircle = true,
  text = '',
}: KpiLabelStatusProps) => {
  const { formatMessage } = useIntl();

  let status;
  if (statusOfKpi === 'overAchieved' || statusOfKpi === 'exceeded') {
    status = 'achieved';
  } else if (statusOfKpi === 'almostAchieved') {
    status = 'inProgress';
  } else {
    status = statusOfKpi;
  }

  const shouldDisplayText = text.length > 0;

  return (
    <Styles.StatusContainer status={status} data-testid="kpi-label-status">
      {hasCircle && <Styles.ColorCircle status={status}></Styles.ColorCircle>}
      <Styles.StatusParagraph size="small" status={status}>
        {shouldDisplayText ? (
          text
        ) : (
          <>
            {statusOfKpi.length > 0 && (
              <>
                {formatMessage({
                  id: `components.generalKpiCardInfo.statusLabel.${status}`,
                })}
              </>
            )}
          </>
        )}
      </Styles.StatusParagraph>
    </Styles.StatusContainer>
  );
};
