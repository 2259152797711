import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

/* eslint-disable react-hooks/rules-of-hooks, @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';

export interface IRequest {
  url: string;
  body?: any;
  config?: AxiosRequestConfig;
}

export interface IRequestFile {
  url: string;
  data?: FormData;
  config?: AxiosRequestConfig;
}

const initialAxios = Axios.create({
  baseURL: window.origin,
});

const onResponse = (response: AxiosResponse): AxiosResponse => response.data;

const onResponseError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

initialAxios.interceptors.response.use(onResponse, onResponseError);

export const authentication = useAuthenticationService();

const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(
  initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        country: userCountry || '',
        noorders: localStorage.getItem('noOrders') || '',
      },
    ],
  }
);

export const post = ({ url, body, config }: IRequest): Promise<any> =>
  initialAxios.post(url, body, { ...config, headers: { ...config?.headers } });

export const remove = ({ url }: IRequest): Promise<any> => initialAxios.delete(url);

export const get = ({ url, config }: IRequest): Promise<any> => initialAxios.get(url, config);

export const put = ({ url, config, body }: IRequest): Promise<any> =>
  initialAxios.put(url, body, config);

export const patch = ({ url, body }: IRequest): Promise<any> => initialAxios.patch(url, body);

export const postFormData = ({ url, data, config }: IRequestFile): Promise<any> =>
  initialAxios.post(url, data, {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data',
    },
  });

export default {
  post,
  remove,
  get,
  put,
  patch,
  postFormData,
};
