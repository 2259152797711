import { localStorageKeys } from '@constants';
import { useLocalStorage } from 'admin-portal-shared-services';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterContext } from './FilterContext';
import { FilterProviderProps } from './FilterProvider.types';

const FilterProvider = ({
  storageKey,
  fields,
  children,
  onChange,
  ...props
}: FilterProviderProps) => {
  const [tempFilters, setTempFilters] = useState({});
  const { localStorageFilters, localStorageDrawerOpen } = localStorageKeys(storageKey);
  const [filters, setFilters] = useLocalStorage(localStorageFilters, {});
  const [drawerOpen, setDrawerOpen] = useLocalStorage(localStorageDrawerOpen, true);

  const handleDelete = useCallback(
    (field: string) => {
      setFilters({ ...filters, [field]: [] });
    },
    [setFilters, filters]
  );

  const handleClear = useCallback(() => {
    setTempFilters({});
  }, []);

  const handleChange = useCallback((field: string, value: string) => {
    setTempFilters((oldTempFilters) => ({
      ...oldTempFilters,
      [field]: [value],
    }));
  }, []);

  const handleApply = useCallback(() => {
    const newFilters = _.cloneDeep(filters);

    Object.keys(tempFilters).forEach((field) => {
      const [newFilterField] = tempFilters[field];
      const single = fields[field]?.single;

      if (newFilterField === '') return;

      if (single || !newFilters[field]?.length) {
        newFilters[field] = [newFilterField];
      } else if (newFilters[field].indexOf(newFilterField) === -1) {
        newFilters[field].push(newFilterField);
      }
    });

    setFilters(newFilters);
    handleClear();
  }, [fields, filters, handleClear, setFilters, tempFilters]);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  useEffect(() => {
    onChange(filters);
  }, [onChange, filters]);

  const value = useMemo(
    () => ({
      fields,
      filters,
      tempFilters,
      handleDelete,
      handleClear,
      handleChange,
      handleApply,
      drawerOpen,
      handleDrawerOpen,
      handleDrawerClose,
      ...props,
    }),
    [
      fields,
      filters,
      tempFilters,
      handleDelete,
      handleClear,
      handleChange,
      handleApply,
      drawerOpen,
      handleDrawerOpen,
      handleDrawerClose,
      props,
    ]
  );

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterProvider;
