export const currency = {
  AR: '$',
  BE: '€',
  BO: 'Bs.',
  BR: 'R$',
  CA: '$',
  CL: '$',
  CO: '$',
  DE: '€',
  DO: 'RD$',
  EC: '$',
  ES: '€',
  GB: '£',
  HN: 'L',
  KR: '₩',
  MX: '$',
  NL: '€',
  PA: 'B/.',
  PE: 'S/',
  PY: 'Gs.',
  SV: '$',
  US: '$',
  UY: '$',
  ZA: 'R',
};
