import { Error403 } from '@hexa-ui/components';
import { useHasPermission } from 'admin-portal-shared-services';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { homePath } from 'routes/Definition';
import { PageErrorWrapper } from './ProtectedRoute.styles';
import { ProtectedRouteProps } from './ProtectedRoute.types';

export const ProtectedRoute = ({
  isEnabled = false,
  children,
  permission,
}: ProtectedRouteProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const hasUserPermission = useHasPermission(permission, 'OR');

  if (isEnabled && hasUserPermission) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <PageErrorWrapper>
      <Error403
        header={formatMessage({ id: 'errorMessage.accessDenied.title' })}
        description={formatMessage({ id: 'errorMessage.accessDenied.subtitle' })}
        buttonText={formatMessage({ id: 'errorMessage.accessDenied.buttonText' })}
        onButtonClick={() => navigate(homePath)}
      />
    </PageErrorWrapper>
  );
};
