import { Check } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { Item } from '@radix-ui/react-select';

export const StyledSelectItem = styled(Item, {
  outline: 'none',
  cursor: 'default',
  borderRadius: '0.25rem',
  '&[data-active-item]': {
    background: '$brandAccentNeutralBackground',
  },
  '&:focus': {
    background: '$brandAccentNeutralBackground',
  },
});

export const StyledDiv = styled('div', {
  marginRight: '5px',
  '[data-testid="checkbox"]': { pointerEvents: 'none' },
});

export const StyledCheckbox = styled('div', {
  all: 'unset',
  backgroundColor: '$neutral0',
  width: 20,
  height: 20,
  borderRadius: '6px',
  display: 'flex',
  border: '1px solid',
  borderColor: '$interfaceLabelSecondary',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  boxSizing: 'border-box',
  '&:hover, &:focus-visible': {
    border: '2px solid',
    borderColor: '$interfaceLabelPrimary',
    '&[data-state=checked], &[data-state=indeterminate]': {
      border: '1px solid',
      borderColor: '$interfaceForegroundLabelPrimary',
      outline: '1px solid $interfaceLabelPrimary',
    },
  },
  '&[data-state=checked], &[data-state=indeterminate]': {
    backgroundColor: '$interfaceLabelPrimary',
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        borderColor: '$interfaceLabelDisabled',
        '&[data-state=checked], &[data-state=indeterminate]': {
          backgroundColor: '$interfaceLabelDisabled',
          borderColor: 'transparent',
        },
      },
    },
  },
});

export const StyledCheckedIcon = styled(Check, {
  color: '#FFF',
  variant: {
    checked: {
      false: {
        display: 'none',
      },
    },
  },
});
