/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import { useAppHeader } from 'admin-portal-shared-services';
import { get as getApi } from '../../api';

export const FEATURE_BASE_URL = 'api/link-commercial-kpis-service/feature';

export const useFeatureService = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;

  const useGet = () => {
    const { data, isLoading, isError } = useQuery(
      [FEATURE_BASE_URL, selectedCountry, `feature-list`],
      () => {
        const url = `${FEATURE_BASE_URL}/featuresList`;

        return getApi({
          url,
          config: {
            headers: {
              country: selectedCountry,
            },
          },
        });
      }
    );

    return { data: data?.content, isLoading, isError };
  };

  return {
    useGet,
  };
};
