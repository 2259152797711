import { TCountryCode } from '@constants';
import { getCountryLanguage } from '@utils';
import { useAuthenticationService, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { IntlProvider as NativeProvider } from 'react-intl';
import messages from '../../i18n';
import MessageMap from './i18n.d';

const DEFAULT_LANGUAGE = 'en-US';

/* eslint-disable no-restricted-syntax, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string) => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export const IntlProvider = ({ children }): JSX.Element => {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = !!authentication
    ? authentication.getUserCountryAndLanguage()
    : { user_country: '' };

  const country = userCountry as TCountryCode;

  const selectedLanguage =
    preferredLanguage === 'es-419' ? getCountryLanguage(country) : preferredLanguage;

  const mergedMessages = mergeMessages(messages, selectedLanguage || defaultLanguage);

  return (
    <NativeProvider
      locale={selectedLanguage || defaultLanguage}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={mergedMessages}
    >
      {children}
    </NativeProvider>
  );
};

export default IntlProvider;
