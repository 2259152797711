import { TCountryCode } from './countries';

type TCurrencyCode = {
  [key in TCountryCode]: { locale: string; currency: string };
};

export const currencyCode: TCurrencyCode = {
  BR: { locale: 'pt-BR', currency: 'BRL' },
  US: { locale: 'en-US', currency: 'USD' },
  ZA: { locale: 'en-ZA', currency: 'ZAR' },
  AR: { locale: 'es-AR', currency: 'ARS' },
  MX: { locale: 'es-MX', currency: 'MXN' },
};
