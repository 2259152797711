/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppHeader, useUserMetadata } from 'admin-portal-shared-services';
import { get as getApi, post, postFormData } from '../../api';
import {
  IProcessingStatusRequest,
  IRequestFileDataRequestProps,
  ISpreeadsheetRequestResponse,
} from './UploadService.types';
import { QUERY_KEY_TEMPLATE, QUERY_KEY_UPLOAD, urlAccordingFeatureAndObjective } from './constants';

export const useUploadService = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const { data: userData } = useUserMetadata();
  const { userId = '', vendors = [], profile = {} } = userData || {};
  const { preferredLanguage = '' } = profile;
  const vendorId = vendors?.[0]?.id || null;

  const featuresWithTemplate = ['JBP', 'IRS', 'ESCALAS'];

  const getUrlType = (featureType) => {
    const feature = featuresWithTemplate.includes(featureType) ? featureType : 'ESCALAS';
    return feature;
  };

  const getSpreeadsheetTemplate = ({ featureType }: IRequestFileDataRequestProps) => {
    const url = urlAccordingFeatureAndObjective[getUrlType(featureType)]?.template;

    const { data, refetch, error } = useQuery<ISpreeadsheetRequestResponse>({
      queryKey: [QUERY_KEY_TEMPLATE, featureType],
      queryFn: () => {
        return post({
          url: url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${QUERY_KEY_TEMPLATE}_${featureType}`,
              feature: getUrlType(featureType),
            },
          },
        });
      },
      enabled: !!featureType,
    });

    let errorMessage: string | null = null;
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    return { data, refetch, error: errorMessage };
  };

  const postFileData = ({ actionType, featureType }: IRequestFileDataRequestProps) => {
    const { mutate, isLoading, isError, reset } = useMutation({
      mutationFn: async (fileToProcess: File) => {
        const data = new FormData();
        data.append('file', fileToProcess);

        const url = urlAccordingFeatureAndObjective[featureType]?.uploadProcess;
        const response = await postFormData({
          url: url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${QUERY_KEY_UPLOAD}_${actionType}`,
            },
          },
          data,
        });

        return response;
      },
    });

    return { mutate, isLoading, isError, reset };
  };

  const postAsyncFileData = ({ actionType, featureType }: IRequestFileDataRequestProps) => {
    const { mutate, isLoading, isError, reset } = useMutation({
      mutationFn: async (fileToProcess: File) => {
        const data = new FormData();
        data.append('file', fileToProcess);

        const url = `${urlAccordingFeatureAndObjective['DEFAULT'].uploadProcess['UPLOAD']}`;
        const response = await postFormData({
          url: url,
          config: {
            headers: {
              country: `${selectedCountry}`,
              requestTraceId: `${QUERY_KEY_UPLOAD}_${actionType}`,
              feature: `${featureType}`,
              language: `${preferredLanguage}`,
              userId: `${userId}`,
              vendorId: `${vendorId}`,
            },
          },
          data,
        });
        return response;
      },
    });

    return { mutate, isLoading, isError, reset };
  };

  const getProcessingStatus = ({ featureType, processId, enable }: IProcessingStatusRequest) => {
    const { refetch, data, error, isError } = useQuery({
      queryKey: ['processingStatus', processId, enable],
      queryFn: async () => {
        const url = `${urlAccordingFeatureAndObjective['DEFAULT'].uploadProcess['PROCESS_ID']}${processId}`;
        const response = await getApi({
          url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${processId}`,
              feature: `${featureType}`,
              language: `${preferredLanguage}`,
              userId: `${userId}`,
              vendorId: `${vendorId}`,
            },
          },
        });

        if (!response) {
          const error = {
            status: 500,
            code: 500,
            message: 'Internal Server Error. Please contact support.',
          };
          throw error;
        }

        const data = response?.spreadsheetContent || response?.spreadsheetDetailContent;

        return data;
      },
      /* istanbul ignore next */
      refetchInterval: (data) => {
        /* istanbul ignore next */
        const needRefetch =
          data?.status === 'processing' || data?.spreadsheet?.status !== 'processed';
        /* istanbul ignore next */
        return needRefetch ? 10000 : false;
      },
      refetchOnWindowFocus: false,
      enabled: enable,
    });

    return { refetch, data: data, error, isError };
  };

  return {
    getSpreeadsheetTemplate,
    postFileData,
    postAsyncFileData,
    getProcessingStatus,
  };
};
