import { currency } from '@constants';

const DEFAULT_CURRENCY = '$';
const WARNING_MESSAGE =
  'Locale is not defined or does not exist in currency object, defaulting to USD';

export const getCurrencyByLocale = (locale: string) => {
  if (!locale || !currency[locale]) {
    console.warn(WARNING_MESSAGE);
    return DEFAULT_CURRENCY;
  }
  return currency[locale];
};
