import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { colors } from './KpiLabelStatus.constants';

export const ColorCircle = styled('span', {
  width: '8px',
  height: '8px',
  borderRadius: '100%',
  variants: {
    status: {
      waitingAcceptance: {
        backgroundColor: colors.waitingAcceptance.circle,
      },
      accepted: {
        backgroundColor: colors.accepted.circle,
      },
      inProgress: {
        backgroundColor: colors.inProgress.circle,
      },
      achieved: {
        backgroundColor: colors.achieved.circle,
      },
      notAchieved: {
        backgroundColor: colors.notAchieved.circle,
      },
      expired: {
        backgroundColor: colors.expired.circle,
      },
      almostAchieved: {
        backgroundColor: colors.almostAchieved.circle,
      },
      overAchieved: {
        backgroundColor: colors.overAchieved.circle,
      },
      exceeded: {
        backgroundColor: colors.overAchieved.circle,
      },
      notStarted: {
        backgroundColor: colors.notStarted.circle,
      },
    },
  },
});

export const StatusContainer = styled('div', {
  alignItems: 'center',
  borderRadius: '$1',
  display: 'flex',
  gap: '$2',
  padding: '$1 $2',
  variants: {
    status: {
      waitingAcceptance: {
        backgroundColor: colors.waitingAcceptance.background,
      },
      accepted: {
        backgroundColor: colors.accepted.background,
      },
      inProgress: {
        backgroundColor: colors.inProgress.background,
      },
      achieved: {
        backgroundColor: colors.achieved.background,
      },
      notAchieved: {
        backgroundColor: colors.notAchieved.background,
      },
      expired: {
        backgroundColor: colors.expired.background,
      },
      almostAchieved: {
        backgroundColor: colors.almostAchieved.background,
      },
      overAchieved: {
        backgroundColor: colors.overAchieved.background,
      },
      exceeded: {
        backgroundColor: colors.overAchieved.background,
      },
      notStarted: {
        backgroundColor: colors.notStarted.background,
      },
    },
  },
});

export const StatusParagraph = styled(Paragraph, {
  variants: {
    status: {
      waitingAcceptance: {
        color: `${colors.waitingAcceptance.text} !important`,
      },
      accepted: {
        color: `${colors.accepted.text} !important`,
      },
      inProgress: {
        color: `${colors.inProgress.text} !important`,
      },
      achieved: {
        color: `${colors.achieved.text} !important`,
      },
      notAchieved: {
        color: `${colors.notAchieved.text} !important`,
      },
      expired: {
        color: `${colors.expired.text} !important`,
      },
      almostAchieved: {
        color: `${colors.almostAchieved.text} !important`,
      },
      overAchieved: {
        color: `${colors.overAchieved.text} !important`,
      },
      exceeded: {
        color: `${colors.overAchieved.text} !important`,
      },
      notStarted: {
        color: `${colors.notStarted.text} !important`,
      },
    },
  },
});
