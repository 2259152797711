import { createContext, useContext } from 'react';
import { FilterContextType } from './FilterContext.types';

const initialValues: FilterContextType = {
  fields: {},
  filters: {},
  table: <></>,
  tempFilters: {},
  handleDelete: () => null,
  handleClear: () => null,
  handleChange: () => null,
  handleApply: () => null,
  drawerOpen: true,
  handleDrawerOpen: () => null,
  handleDrawerClose: () => null,
};

export const FilterContext = createContext(initialValues);
export const useFilterContext = (): FilterContextType => useContext(FilterContext);
