import { currency } from '@constants';
import { NumberFormatProps, NumberFormatValues } from './numberFormat.types';

const getStandardNumberFormatValues = (locale: string, fractionDigits = 2) => ({
  currency: {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    style: 'decimal',
  },
  locale,
});

const dictionary: Record<string, NumberFormatValues> = {
  AR: getStandardNumberFormatValues('es-AR'),
  BE: getStandardNumberFormatValues('fr-BE'),
  BO: getStandardNumberFormatValues('es-BO'),
  BR: getStandardNumberFormatValues('pt-BR'),
  CA: getStandardNumberFormatValues('en-CA'),
  CL: getStandardNumberFormatValues('pt-BR'), // grouped by 2 numbers, as in pt-BR
  CO: getStandardNumberFormatValues('es-CO', 1), // grouped by 1 numbers
  DE: getStandardNumberFormatValues('de-DE'),
  DO: getStandardNumberFormatValues('en-US'),
  EC: getStandardNumberFormatValues('en-US'),
  ES: getStandardNumberFormatValues('es-ES'),
  GB: getStandardNumberFormatValues('en-GB'),
  HN: getStandardNumberFormatValues('en-US'),
  KR: getStandardNumberFormatValues('ko-KR', 0),
  MX: getStandardNumberFormatValues('en-US'),
  NL: getStandardNumberFormatValues('nl-NL'),
  PA: getStandardNumberFormatValues('es-PA'),
  PE: getStandardNumberFormatValues('en-US'),
  PY: getStandardNumberFormatValues('pt-BR', 0),
  SV: getStandardNumberFormatValues('en-US'),
  US: getStandardNumberFormatValues('en-US'),
  UY: getStandardNumberFormatValues('pt-BR'),
  ZA: getStandardNumberFormatValues('en-ZA'),
};

export const numberFormat = ({
  value,
  locale,
  style = undefined,
  options = {},
}: NumberFormatProps) => {
  const hasDecimals = !Number.isInteger(value);
  const precision = hasDecimals ? 2 : 0;

  if (typeof Intl === 'object' && typeof Intl.NumberFormat === 'function') {
    const formattedValue = new Intl.NumberFormat(dictionary[locale].locale, {
      style,
      ...dictionary[locale][style || 'decimal'],
      ...options,
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }).format(value);

    if (style !== 'currency') {
      return formattedValue;
    }

    switch (locale) {
      case 'CA':
        return `${formattedValue}${'\u00A0'}${currency[locale]}`;
      case 'ZA':
        return `${currency[locale]}${'\u00A0'}${formattedValue
          .replace(/,/g, '\u00A0')
          .replace('.', ',')}`;
      default:
        return `${currency[locale]}${'\u00A0'}${formattedValue}`;
    }
  }

  return value.toString();
};
