export const QUERY_KEY_TEMPLATE = 'template';

export const QUERY_KEY_UPLOAD = 'upload';

export const SPREEADSHEET_JBP_TEMPLATE_URL = `api/link-commercial-kpis-service/driver/template`;

export const SPREEADSHEET_JBP_UPLOAD_URL = `api/link-commercial-kpis-service/driver/template/upload`;

export const SPREADSHEETS_IRS_TEMPLATE_URL = `api/link-commercial-kpis-service/contract/template`;

export const SPREADSHEETS_AR_TEMPLATE_URL = `api/link-commercial-kpis-service/spreadsheet/generator/kpi`;

export const SPREADSHEETS_AR_PROCESSING = `api/link-commercial-kpis-service/driver/template/upload?fileType=AR`;

export const SPREEADSHEET_ASYNC_UPLOAD_URL = `api/link-commercial-kpis-service/spreadsheet/upload`;

export const SPREEADSHEET_ASYNC_PROCESSING = `api/link-commercial-kpis-service/spreadsheet?processId=`;

export const FEATURE_TYPE = {
  JBP: 'JBP',
  IRS: 'IRS',
  ESCALAS: 'ESCALAS',
};

export const urlAccordingFeatureAndObjective = {
  IRS: {
    template: SPREADSHEETS_IRS_TEMPLATE_URL,
    uploadProcess: SPREEADSHEET_ASYNC_UPLOAD_URL,
  },
  ESCALAS: {
    template: SPREADSHEETS_AR_TEMPLATE_URL,
    uploadProcess: SPREADSHEETS_AR_PROCESSING,
  },
  JBP: {
    template: SPREEADSHEET_JBP_TEMPLATE_URL,
    uploadProcess: SPREEADSHEET_JBP_UPLOAD_URL,
  },
  DEFAULT: {
    template: SPREADSHEETS_AR_TEMPLATE_URL,
    uploadProcess: {
      UPLOAD: SPREEADSHEET_ASYNC_UPLOAD_URL,
      PROCESS_ID: SPREEADSHEET_ASYNC_PROCESSING,
    },
  },
};
