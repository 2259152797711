export const colors = {
  waitingAcceptance: {
    background: '#FFF7EB',
    circle: '#FFAA33',
    text: '#D9912B',
  },
  accepted: {
    background: '#F6F2FF',
    circle: '#A080FF',
    text: '#886DD9',
  },
  inProgress: {
    background: '#E7F2FF',
    circle: '#0F7FFA',
    text: '#0F7FFA',
  },
  achieved: {
    background: '#EAF8EE',
    circle: '#31BD54',
    text: '#2AA147',
  },
  notAchieved: {
    background: '#FCECEB',
    circle: '#E53C32',
    text: '#C3332B',
  },
  expired: {
    background: 'rgba(20, 20, 20, 0.08)',
    circle: 'rgba(20, 20, 20, 0.32)',
    text: 'rgba(20, 20, 20, 0.32)',
  },
  overAchieved: {
    background: '#EAF8EE',
    circle: '#31BD54',
    text: '#2AA147',
  },
  almostAchieved: {
    background: '#FFF7EB',
    circle: '#FFAA33',
    text: '#D9912B',
  },
  notStarted: {
    background: '#FFFBEB',
    circle: '#D9B62B',
    text: '#D9B62B',
  },
};
