/* eslint-disable @typescript-eslint/no-empty-function */
import { Paragraph, SearchField, TextLink } from '@hexa-ui/components';
import { Store2 } from '@hexa-ui/icons';
import { Root as SelectRoot } from 'components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Styles from './SelectWithSearch.styles';
import { Account, SelectWithSearchProps } from './SelectWithSearch.types';

export const SelectWithSearch = ({
  isLoading,
  accounts,
  isChainLevel = true,
  defaultAccount,
  onAccountChange,
  defaultStoreState: defaultStore,
  setDefaultStoreState: setDefaultStore,
}: SelectWithSearchProps) => {
  const { formatMessage } = useIntl();
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLButtonElement>(null);
  const [click, setClick] = useState(0);
  const [showChainDefault, setShowChainDefault] = useState(true);

  const showAllStores = isChainLevel && showChainDefault && !defaultStore.hasFilter;

  const filterAndSortAccounts = (
    accounts: Account[],
    searchTerm: string,
    isChainLevel: boolean
  ): Account[] => {
    const normalizedTerm = searchTerm.toLowerCase();

    const matchesSearchTerm = (account: Account) => {
      const identifier = isChainLevel ? account.name : account.clientName;
      const id = isChainLevel ? account.accountId : account.clientId;
      return identifier.toLowerCase().includes(normalizedTerm) || id.includes(searchTerm);
    };

    return accounts
      .filter(matchesSearchTerm)
      .sort((a, b) =>
        (isChainLevel ? a.name : a.clientName).localeCompare(isChainLevel ? b.name : b.clientName)
      );
  };

  const filteredList = useMemo(() => {
    if (searchTerm.length <= 2) {
      return accounts.sort((a, b) =>
        (isChainLevel ? a.name : a.clientName).localeCompare(isChainLevel ? b.name : b.clientName)
      );
    }

    return filterAndSortAccounts(accounts, searchTerm, isChainLevel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, accounts]);

  useEffect(() => {}, [selectedAccount, click]);

  const resetSearchTerm = () => {
    setSearchTerm('');
  };

  const handleSearch = (term) => {
    const searchTerm = term.target.value;
    setSearchTerm(searchTerm);
  };

  const onClear = () => {
    resetSearchTerm();
  };

  const loadAgainAllStores = () => {
    setClick((prev) => prev + 1);
    setShowChainDefault(true);
    setSelectedAccount(defaultAccount);
    setDefaultStore({
      name: '',
      accountId: '',
      vendorId: '',
      hasFilter: false,
    });
    onAccountChange();
    resetSearchTerm();
  };

  const handleClick = (account) => {
    setClick((prev) => prev + 1);
    setShowChainDefault(false);
    setSelectedAccount(account);
    setDefaultStore({
      name: isChainLevel ? account.name : account.clientName,
      accountId: isChainLevel ? account.accountId : account.clientId,
      vendorId: account.vendorId,
      hasFilter: true,
    });
    onAccountChange();
  };

  const handleOnClose = () => {
    resetSearchTerm();
  };

  if (isLoading) {
    return null;
  }

  return (
    <Styles.Wrapper>
      <SelectRoot
        name="select-with-search"
        shape="pill"
        size="small"
        icon={<Store2 />}
        onBlur={() => searchFieldRef.current?.focus()}
        onClose={handleOnClose}
        ref={selectRef}
        clicked={click}
        value={
          showAllStores
            ? formatMessage({ id: 'components.selectWithSearch.allStores' })
            : formatMessage({ id: 'components.selectWithSearch.storeName' })
                .replace('%storeName', defaultStore.name)
                .replace('%storeCode', defaultStore.accountId)
        }
      >
        <Styles.ListWrapper>
          <Styles.SearchFieldWrapper>
            <SearchField.Root
              placeholder={formatMessage({ id: 'components.selectWithSearch.searchPlaceholder' })}
              onChange={(e) => handleSearch(e)}
              onClear={() => onClear()}
              onBlur={() => selectRef.current?.click()}
              autoFocus={true}
              ref={searchFieldRef}
              value={searchTerm}
            />
          </Styles.SearchFieldWrapper>
          {filteredList.length > 0 ? (
            <>
              <Styles.TextLinkWrapper>
                <TextLink hasUnderline={false} onClick={() => loadAgainAllStores()}>
                  {formatMessage({ id: 'components.selectWithSearch.showAllStores' })}
                </TextLink>
              </Styles.TextLinkWrapper>
              {filteredList.map((account) => (
                <Styles.Option
                  key={isChainLevel ? account.accountId : account.clientId}
                  onClick={() => handleClick(account)}
                >
                  <Paragraph>
                    {formatMessage({ id: 'components.selectWithSearch.storeName' })
                      .replace('%storeName', isChainLevel ? account.name : account.clientName)
                      .replace('%storeCode', isChainLevel ? account.accountId : account.clientId)}
                  </Paragraph>
                </Styles.Option>
              ))}
            </>
          ) : (
            <Styles.NoResultsWrapper>
              <Paragraph size="xsmall" colortype="secondary" alignment="center">
                {formatMessage({ id: 'components.selectWithSearch.noResults' })}
              </Paragraph>
            </Styles.NoResultsWrapper>
          )}
        </Styles.ListWrapper>
      </SelectRoot>
    </Styles.Wrapper>
  );
};
