export type TCountryCode = 'BR' | 'US' | 'ZA' | 'AR' | 'MX';

type TCountryName = {
  [key in TCountryCode]: string;
};

export const countryName: TCountryName = {
  BR: 'BRAZIL',
  US: 'UNITED_STATES',
  ZA: 'SOUTH_AFRICA',
  AR: 'ARGENTINA',
  MX: 'MEXICO',
};

type TCountryLanguage = {
  [key in TCountryCode]: string;
};

export const countryLanguage: TCountryLanguage = {
  BR: 'pt-BR',
  US: 'en-US',
  ZA: 'en-ZA',
  AR: 'es-AR',
  MX: 'es-MX',
};
