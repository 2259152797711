import { styled } from '@hexa-ui/theme';

export const ItemDefaultTrue = styled('div', {
  display: 'flex',
  height: '52px',
  padding: '0px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '8px',
  borderBottom: '2px solid var(--border-default, #141414)',
  cursor: 'pointer',
});

export const TextTrue = styled('span', {
  fontFamily: 'barlow',
  fontsize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  color: 'var(--txt-default, #141414)',
  textAlign: 'center',
});

export const ItemDefaultFalse = styled('div', {
  display: 'flex',
  height: '52px',
  padding: '0px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '8px',
  cursor: 'pointer',
});

export const TextFalse = styled('span', {
  color: 'var(--txt-secondary, rgba(20, 20, 20, 0.56))',
  textAlign: 'center',
  fontFamily: 'barlow',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
});
