export const getFormattedKpiGroupName = (group: string) => {
  const words = group.trim().split(' ');
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const camelCasePhrase = camelCaseWords.join('');

  return camelCasePhrase;
};
