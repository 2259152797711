import { Stack } from '@mui/material';
import { FilterProps } from './Filter.types';
import { FilterContent } from './FilterContent/FilterContent';
import { FilterDrawer } from './FilterDrawer/FilterDrawer';
import FilterProvider from './context/FilterProvider';

export const Filter = (props: FilterProps) => {
  return (
    <FilterProvider {...props}>
      <Stack data-testid="filter" direction="row">
        <FilterDrawer />
        <FilterContent />
      </Stack>
    </FilterProvider>
  );
};

export * from './Filter.types';
