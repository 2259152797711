import { ItemDefaultFalse, ItemDefaultTrue, TextFalse, TextTrue } from './TabMenuItem.styles';
import { TabMenuItemProps } from './TabMenuItem.types';
import { TabMenuItemLoading } from './TabMenuItemLoading';

const defaultOnClick = () => {
  console.warn('onClick handler is not provided');
};

export const TabMenuItem = ({
  content,
  onClick = defaultOnClick,
  active,
  isLoading,
}: TabMenuItemProps) => {
  if (isLoading) {
    return <TabMenuItemLoading />;
  }

  const ItemComponent = active === true ? ItemDefaultTrue : ItemDefaultFalse;
  const TextComponent = active === true ? TextTrue : TextFalse;

  return (
    <ItemComponent onClick={onClick} data-testid={`tab-menu-item-${content}`}>
      <TextComponent>{content}</TextComponent>
    </ItemComponent>
  );
};
