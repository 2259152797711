/* eslint-disable react-hooks/rules-of-hooks */
import { useIntl } from 'react-intl';

export const getMeasurementDescription = (type, description) => {
  const { formatMessage } = useIntl();
  const start = description.indexOf('(') + 1;
  const end = description.indexOf(')');
  const value = description.substring(start, end);
  const unitsMeasurement = value.length > 0 ? value : type;
  let measureDescription = '';

  switch (type) {
    case '$':
      measureDescription = 'currency';
      break;
    case 'Vol.':
      measureDescription = 'volume';
      break;
    case '#':
      measureDescription = 'number';
      break;
    case '%':
      measureDescription = 'percentage';
      break;
  }

  return `${formatMessage({
    id: `pages.JBPContractManagment.details.measurement.${measureDescription}`,
  })} (${unitsMeasurement})`;
};
